import React from "react";
import PropTypes from "prop-types";
import { graphql, withPrefix } from "gatsby";

import Layout from "../components/Layout";
import { Col, Container, Row } from "react-awesome-styled-grid";
import { theme } from "../theme/theme";
import { Image } from "../components/atoms/Image";
import { Heading } from "../components/atoms/Heading";
import { Text } from "../components/atoms/Text";
import { Button } from "../components/atoms/Button";

export const DeBomenPageTemplate = () => (
  <Container>
    <Row align="center" style={{minHeight: '50vh', marginTop: theme.space['10']}}>
      <Col xs={12} md={6}>
        <Image src={`${withPrefix('/')}img/de-bomen-black-background.jpg`} alt="Wildwood bomen" />
      </Col>
      <Col xs={12} md={4} offset={{md: 1}}>
        <Heading as="h1" variant="heading1">De houten kerstbomen</Heading>
        <Text>Er is altijd een Wildwood voor jouw situatie. Woon je in een knus appartement, dan is de Tabletop echt een eyecatcher. De Classic is de ideale vervanger voor een normale kerstboom. Voor mensen met flink wat ruimte is er de Plus. En voor receptieruimtes, restaurants en liefhebbers staat de Grand garant voor bewonderende blikken.</Text>
        <Text><Button variant="primary" to="/contact">Contact & Bestel</Button></Text>
      </Col>
    </Row>
    <Row style={{marginTop: theme.space['20']}}>
      <Col xs={6} md={3}>
        <Image src={`${withPrefix('/')}img/bomen-tabletop.jpg`} alt="Wildwood Tabletop" />
        <Heading as="h3" variant="heading2" style={{marginTop: theme.space['5']}}>Tabletop</Heading>
        <Text>Hoogte: 35cm<br/>
        Breedte: 23cm<br/>
        Voet: Ja</Text>
        <Text>Prijs: &euro; 45,00</Text>
      </Col>
      <Col xs={6} md={3}>
        <Image src={`${withPrefix('/')}img/bomen-classic-wide.jpg`} alt="Wildwood Classic" />
        <Heading as="h3" variant="heading2" style={{marginTop: theme.space['5']}}>Classic</Heading>
        <Text>Hoogte: 75cm<br/>
        Breedte: 61cm<br/>
        Voet: Ja</Text>
        <Text>Prijs: &euro; 95,00</Text>
      </Col>
      <Col xs={6} md={3}>
        <Image src={`${withPrefix('/')}img/bomen-plus.jpg`} alt="Wildwood Plus" />
        <Heading as="h3" variant="heading2" style={{marginTop: theme.space['5']}}>Plus</Heading>
        <Text>Hoogte: 110cm<br/>
        Breedte: 74cm<br/>
        Voet: Ja</Text>
        <Text>Prijs: &euro; 200,00</Text>
      </Col>
      <Col xs={6} md={3}>
        <Image src={`${withPrefix('/')}img/bomen-grand.jpg`} alt="Wildwood Grand" />
        <Heading as="h3" variant="heading2" style={{marginTop: theme.space['5']}}>Grand</Heading>
        <Text>Hoogte: 170cm<br/>
        Breedte: 114cm<br/>
        Voet: Ja</Text>
        <Text>Prijs: &euro; 350,-</Text>
      </Col>
    </Row>
  </Container>
);

DeBomenPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const DeBomenPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <DeBomenPageTemplate
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

DeBomenPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default DeBomenPage

export const deBomenPageQuery = graphql`
  query DeBomenPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
